<template>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
          <div class="container containerDash">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sidebar-dashboard-customer></sidebar-dashboard-customer>
              </div>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-10"><h3>Package Redeem History</h3></div>
                  <h6 class="printLogo" style="display: none">iBidz</h6>
                  <div class="col-2">
                    <button
                      v-if="customerList.length"
                      class="btn btn-primary noPrint"
                      style="margin-top: 0px"
                      @click="printWindow()"
                    >
                      Print
                    </button>
                  </div>
                </div>

                <div v-if="!customerList.length">
                  <notfound :checkdata="initialLoading"></notfound>
                </div>
                <div v-else>
                  <section
                    style="background-color: #f1f1f1; margin-top: 20px"
                    class="autionlog-table"
                  >
                    <!-- STARTS HERE -->
                    <div class="table-wrapper">
                      <div class="table-cont">
                        <div class="rowz headerzz">
                          <div class="cellz">Id</div>
                          <div class="cellz">Package Name</div>
                          <div class="cellz">Redeem Amount</div>
                          <div class="cellz">Redeem Date</div>
                        </div>

                        <div
                          class="rowz"
                          v-for="list in customerList"
                          :key="list.acuId"
                        >
                          <div class="cellz" data-title="Id">
                            {{list.phHistoryId }}
                          </div>
                          <div class="cellz" data-title="Package Name">
                            {{ !list.packName ? "Default" : list.packName }}
                          </div>
                          <div class="cellz" data-title="Claim Amount">
                            {{ list.packAmount | currency }}
                          </div>
                          <div class="cellz" data-title="Claime Date">
                            {{ list.packClaimDate | formatDatetime  }}
                            <!-- {{moment(String(new Date(list.packClaimDate))).format("DD/MM/YYYY hh:mm a")}} -->
                            <!-- {{ new Date(list.packClaimDate).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }) }} -->
                          </div>
                          <!-- <div class="cellz" data-title="Token Amount">
                                    {{ list.tokenAmount | currency }}
                                  </div> -->
                        </div>
                      </div>
                    </div>

                    <!-- .container -->
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import userService from "@/services/user.service";
import Config from "@/config";
import moment from "moment";
export default {
  data() {
    return {
      customerList: "",
      path: Config.BASE_URL_IMG,
      initialLoading: true,
    };
  },
  created() {
    if (this.loggedIn) {
      if (
        !this.$store.state.auth.user[0].customerId == "" ||
        !this.$store.state.auth.user[0].customerId == " "
      ) {
        this.$router.push("/ClaimHistory").catch(() => {
          "home";
        });
      } else {
        this.$router.push("/").catch(() => {
          "home";
        });
      }
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  mounted() {
    if (this.$store.state.auth.user) {
      this.st = this.$store.state.auth.user[0];

      this.getPackageClaimHistory(this.st.customerId);
    } else {
      this.$router.push("/").catch(() => {
        "myaccount";
      });
    }
  },
  methods: {
    printWindow() {
      window.print();
    },
    getPackageClaimHistory(custId) {
      // console.log(custId);
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getPackageClaimHistory(custId,'customer')
          .then((response) => {
            this.customerList = response.data.Data;
            // console.log(this.customerList);
            this.initialLoading = false;
          })
          .catch((e) => {
            console.log(e);
            this.initialLoading = false;
          });
      }
    },
  },
  filters: {
    currency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      let country = Config.countryCode;
      let crncy = Config.curency;
      let FractionDigits = Config.minFractionDigits;
      const formatter = new Intl.NumberFormat(country, {
        style: "currency",
        currency: crncy,
        minimumFractionDigits: FractionDigits,
      });
      return formatter.format(value);
    },
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY");
      }
    },
    formatDatetime: function (value) {
      if (value) {
        return moment(String(value)).format("D-MMM-YYYY hh:mm a");
      }
    },
  },
};
</script>
<style scoped>
th label {
  margin-bottom: 0px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: 0.5em 0 0.75em;
}
table tr {
  background-color: #fff;
  border: 1px solid #e1e5e9;
  padding: 0.35em;
  border-radius: 3px;
}
table thead tr:first-child {
  border: 1px solid #0094da;
}
table th,
table td {
  padding: 8px;
  text-align: left;
  color: #202020;
  font-size: 14px;
}

table td:nth-child(4) {
  font-size: 18px;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background: #0094da;
  color: #fff;
}

table tbody tr td .btn-invoice {
  background: #0094da;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  border: 0;
  width: 100%;
  border-radius: 5px;
}

tbody tr:nth-child(even) {
  background-color: #eee;
}

tbody tr:nth-child(odd) {
  background-color: #fff;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }
  table caption {
    font-size: 1.3em;
  }
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
    padding: 0;
  }
  table tr {
    border-bottom: 3px solid #e1e5e9;
    display: block;
    margin-bottom: 0.625em;
  }
  table th,
  table td {
    padding: 0.625em;
  }
  table td {
    border-bottom: 1px solid #e1e5e9;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #9da9b9;
  }
  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    color: #656971;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:nth-child(4) {
    font-size: 0.8em;
  }
}
</style>
